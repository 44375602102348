import React from 'react'
import Header from './Header'
import Footer from './Footer'
import '../CSS/home.css';

function Home() {
  return (
    <>
      <Header/>
      <div className="container">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 h-fit border-2 border-black ">
          
        </div>

        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 h-fit border-2 border-black">

        </div>

        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 h-fit border-2 border-black">

        </div>

        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 h-fit border-2 border-black">
          
        </div>

      </div>

      <Footer/>
    </>
  )
}

export default Home